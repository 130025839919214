import { getUUID } from "./utils"

export type ToastType = 'default' | 'loading' | 'success' | 'warning' | 'error'

export type ToastParams = {
  message: string
  type?: ToastType
  icon?: string
  inverted?: boolean
}

export type FutureToastParams = ToastParams & { id: string }

class FutureToast {
  readonly #id: string

  #params: ToastParams

  constructor (id: string, params: ToastParams) {
    this.#id = id
    this.#params = params
  }

  update (params: ToastParams) {
    this.#params = params

    this.#persist()
  }

  remove () {
    this.#remove()
  }

  set inverted(value: boolean | undefined) {
    this.#params.inverted = value

    this.#persist()
  }

  get inverted () {
    return this.#params.inverted
  }

  set type(type: ToastType) {
    this.#params.type = type

    this.#persist()
  }

  get type () {
    return this.#params.type ?? 'default'
  }

  set icon(icon: string | undefined) {
    this.#params.icon = icon

    this.#persist()
  }

  get icon () {
    return this.#params.icon
  }

  set message(message: string) {
    this.#params.message = message

    this.#persist()
  }

  get message () {
    return this.#params.message
  }

  #remove () {
    window._vToastInternals.futureToasts = window._vToastInternals.futureToasts.filter((toast) => toast.id !== this.#id)
  }

  #persist () {
    const toasts = window._vToastInternals.futureToasts

    const toastIndex = toasts.findIndex((toast) => toast.id === this.#id)
    if (toastIndex !== -1) {
      toasts[toastIndex] = {
        ...this.#params,
        id: this.#id
      }

      window._vToastInternals.futureToasts = toasts
    }
  }
}

class Toast {
  readonly #element: HTMLElement
  readonly #textElement: HTMLElement
  readonly #iconElement: HTMLElement

  #timeout1?: NodeJS.Timeout
  #timeout2?: NodeJS.Timeout

  constructor(element: HTMLElement, params: ToastParams) {
    this.#element = element
    this.#textElement = element.querySelector('.avv-toast-text') as HTMLElement
    this.#iconElement = element.querySelector(
      '.avv-toast-icon > i'
    ) as HTMLElement

    this.update(params)

    setTimeout(() => this.#element.classList.add('visible'), 0)
  }

  update(params: ToastParams) {
    this.type = params.type ?? 'default'
    this.icon = params.icon
    this.message = params.message
    this.inverted = params.inverted
  }

  remove() {
    this.#stopDecay()

    this.#element.remove()
  }

  set inverted(value: boolean | undefined) {
    if (value) {
      this.#element.classList.add('with-inverted-colors')
    } else {
      this.#element.classList.remove('with-inverted-colors')
    }
  }

  set type(type: ToastType) {
    this.#element.setAttribute('data-type', type)

    if (type === 'loading') {
      this.#stopDecay()
    } else {
      this.#startDecay()
    }
  }

  set icon(icon: string | undefined) {
    if (typeof icon === 'string') {
      this.#element.classList.add('with-icon')

      this.#iconElement.innerText = icon
    } else {
      this.#element.classList.remove('with-icon')
    }
  }

  set message(message: string) {
    this.#textElement.innerText = message
  }

  #startDecay() {
    this.#timeout1 = setTimeout(
      () => this.#element.classList.remove('visible'),
      2000
    )
    this.#timeout2 = setTimeout(() => this.#element.remove(), 2500)
  }

  #stopDecay() {
    clearTimeout(this.#timeout1)
    clearTimeout(this.#timeout2)
  }
}

const FUTURE_TOAST_LS_KEY = 'avvokaFutureToasts'

declare global {
  interface Window {
    _vToastInternals: {
      container: HTMLElement

      get futureToasts(): FutureToastParams[]
      set futureToasts(toasts: FutureToastParams[])
    }
  }
}

window._vToastInternals ??= {
  container: (() => {
    let element = document.getElementById('v-toast-container')
    if (element) return element

    element = document.createElement('div')
    element.id = 'v-toast-container'
    element.setAttribute('class', 'avv-toast-container')

    document.body.appendChild(element)

    return element
  })(),
  get futureToasts () {
    return JSON.parse(window.localStorage.getItem(FUTURE_TOAST_LS_KEY) ?? '[]') as FutureToastParams[]
  },
  set futureToasts (toasts: FutureToastParams[]) {
    window.localStorage.setItem(FUTURE_TOAST_LS_KEY, JSON.stringify(toasts))
  }
}

void function () {
  for (const toast of window._vToastInternals.futureToasts) {
    useToast(toast)
  }

  window._vToastInternals.futureToasts = []
}()

export function useToast (params: ToastParams, options: { later: true }): FutureToast
export function useToast (params: ToastParams, options: { later?: boolean }): Toast
export function useToast (params: ToastParams): Toast

export function useToast (params: ToastParams, options: { later?: boolean } = {}): Toast | FutureToast {
  const id = getUUID()

  if (options.later) {
    window._vToastInternals.futureToasts = window._vToastInternals.futureToasts.concat([
      {
        id,
        ...params
      }
    ])

    return new FutureToast(id, params)
  } else {
    const element = document.createElement('div') as HTMLDivElement & {
      $toast: Toast
    }

    element.classList.add('avv-toast')
    element.id = id
    element.innerHTML = '<div class="avv-toast-icon"><i aria-hidden="true" class="material-symbols-outlined"></i></div><div class="avv-toast-text"></div>'

    // Insert into container
    window._vToastInternals.container.insertAdjacentElement('afterbegin', element)
  
    // Return as toast object
    const toast = new Toast(element, params)

    element.$toast = toast
  
    return toast
  }
}

export function useErrorToast (errorOrMessage: unknown, options: { later?: boolean } = {}) {
  return useToast({
    type: 'error',
    message: errorOrMessage instanceof Error ? errorOrMessage.message : errorOrMessage as string
  }, options)
}
